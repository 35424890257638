import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup';

const EmployerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('*'),
  address: Yup.string()
    .min(10, 'Too Short!')
    .required('Required'),
  email: Yup.string()
    .min(5, 'Too Short!')
    .required('Required'),
  contact: Yup.string()
    .required('Required'),
 
});

export default function EmployerForm() {
  return (
    <>
      
      <Formik
        validationSchema={EmployerSchema}
        initialValues={{ name: "", designation: "", company_name: "", address: "", contact: "", email: "", no_of_employees: "", trade: "", contract_date: "", demand_ltr: "", development_date: "", payment_ttl: "" , payment_pyd:"",payment_lft:"",}}
        onSubmit={async (values, { resetForm }) => {
          await fetch('http://localhost:3000/admin/api/resources/Employers/actions/new', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
          .then((response) => response.json())
          .then((json) => {
              console.log(json)
              if(json.notice.type ==='success'){
                  alert('Submitted Successfully')
                  resetForm();
              }
          });
        }}
      >
        {({ errors, touched }) => (
        

        <div className="container">
        <div className="py-5 text-center">
            <img className="d-block mx-auto mb-4" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTdxP6jtxuzxOrHjumEk-8wI0yJrSeGAVVyppgr0g8MW5vXa0j1HzyP75y-wNwmPTGH8g&usqp=CAU" alt="" width={72} height={72} />
            <h2>Employers Form</h2>
            {/* <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
        </div>
        <h4>Level 1</h4>
        <div className="row">
            <div className="col-md-8 order-md-1">
               
                <Form className="needs-validation" noValidate>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="name">Name</label>
                            {/* Enter your name: */}
                            <Field name="name" className="form-control" type="text" />
                            <small className="text-muted">Full name as on Documents</small>
                            {/* <input type="text" className="form-control" id="name" /> */}

                        </div>
                        <div className="col-md-6 mb-3">
                        {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="designation">Designation</label>
                            <Field name="designation" className="form-control" type="text" />
                            {/* <input type="text" className="form-control" id="address" /> */}
                           
                        </div>
                    </div>
                  <div className='row' >
                    <div className="col-md-6 mb-3">
                            {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="company_name">Company Name</label>
                            {/* Enter your name: */}
                            <Field name="company_name" className="form-control" type="text" />
                            <small className="text-muted">Full company name</small>
                            {/* <input type="text" className="form-control" id="name" /> */}

                        </div>
                        <div className="col-md-6 mb-3">
                      {errors.name && touched.name ? (
                        <div>{errors.name} </div>
                      ) : null}
                      <label htmlFor="address">Company Address</label>
                      <Field name="address" className="form-control" type="text" placeholder="1234 Main St" />
                      {/* <input type="text" className="form-control" id="address" /> */}

                    </div>
                    </div>
                    <div className="mb-3">
                    {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="contact">Contact</label>
                            <Field name="contact" className="form-control" type="tel" />
                    </div>
                    <div className="mb-3">
                    {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="email">Email Address</label>
                            <Field name="email" className="form-control" type="email" placeholder="you@example.com"></Field>
                    </div>
                   <hr></hr>
                   <br></br>
                   <br></br>
                   <h4>Level 2</h4>
                   <div className="row">
                        <div className="col-md-6 mb-3">
                            {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="no_of_employees">Number Of Employees</label>
                       
                            <Field name="no_of_employees" className="form-control" type="number" />
                            
                           

                        </div>
                        <div className="col-md-6 mb-3">
                        {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="trade">Trade</label>
                            <Field name="trade" className="form-control" type="text" />
                            
                           
                        </div>
                    </div>
                  <div className='row' >
                  <div className="mb-3">
                    {errors.name && touched.name ? (
                      <div>{errors.name} </div>
                    ) : null}
                    <label htmlFor="contract_date">Date of Contract</label>
                    <Field name="contract_date" className="form-control" type="date" />
                  </div>
                  </div>
                  <div className="mb-3">
                    {errors.name && touched.name ? (
                      <div>{errors.name} </div>
                    ) : null}
                    <label htmlFor="demand_ltr">Demand Letter (upload documents / pdf)</label>

                    <Field name="demand_ltr" className="form-control" type="file" />
                  </div>
                  <div className="mb-3">
                    {errors.name && touched.name ? (
                      <div>{errors.name} </div>
                    ) : null}
                    <label htmlFor="development_date">Date of Development</label>
                    <Field name="development_date" className="form-control" type="date" />
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                    <label htmlFor="payment_ttl">Total Payment</label>
                       
                       <Field name="payment_ttl" className="form-control" type="number" />
                    </div>
                    <div className="col-md-3 mb-3">
                    <label htmlFor="payment_pyd">Payment Payed</label>
                       
                       <Field name="payment_pyd" className="form-control" type="number" />
                    </div>
                    <div className="col-md-3 mb-3">
                    <label htmlFor="payment_lft">Payment Payable</label>
                       
                       <Field name="payment_lft" className="form-control" type="number" />

                    </div>
                       
                   <hr></hr>
                   

                       
                   
                   
                   
                   </div>
                   
                    <button className="btn btn-dark btn-lg btn-block" type="submit">Continue to checkout</button>
                </Form>
            </div>
        </div>
        <footer className="my-5 pt-5 text-muted text-center text-small">
            <p className="mb-1">© 2017-2018 Company Name</p>
            <ul className="list-inline">
                <li className="list-inline-item"><a href="#">Privacy</a></li>
                <li className="list-inline-item"><a href="#">Terms</a></li>
                <li className="list-inline-item"><a href="#">Support</a></li>
            </ul>
        </footer>
    </div>


          

)}
</Formik>

</>
)
}