import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup';
import MyField from './MyField';

const ClientS1Schema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("*"),
  address: Yup.string()
    .min(10, 'Too Short!')
    .required('Required'),
  photo: Yup.string()
    .min(10, 'Too Short!'),
  contact: Yup.string()
    .required('Required'),
  dob: Yup.date()
    .required('Required'),
  passport_no: Yup.string()
    .required('Required'),
  passport_exp_date: Yup.date()
    .required('Required'),
  cnic: Yup.string()
    .required('Required'),
  qualification: Yup.string()
    .required('Required'),
  trade_applied: Yup.string()
    .required('Required'),
  country_applied: Yup.string()
    .required('Required'),
  reference: Yup.string()
    .required('Required'),
  local_exp_yrs: Yup.string()
    .required('Required'),
  foreign_exp_country: Yup.string()
    .required('Required'),
  foreign_exp_yrs: Yup.string()
    .required('Required'),
  languages: Yup.string()
    .required('Required'),

});

export default function ClientS1Form() {
  return (
    <>

      <Formik
        validationSchema={ClientS1Schema}
        initialValues={{
          name: "", address: "", photo: "", contact: "", dob: "", passport_no: "", passport_exp_date: "", cnic: "", qualification: "", trade_applied: "", country_applied: "", reference: "", local_exp_yrs: "", foreign_exp_country: "", foreign_exp_yrs: "", languages: "", 
        }}
        onSubmit={async (values, { resetForm }) => {
          await fetch('http://localhost:3008/admin/api/resources/ClientsS1/actions/new', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
          .then((response) => response.json())
          .then((json) => {
              console.log(json)
              if(json.notice.type ==='success'){
                  alert('Submitted Successfully')
                  resetForm();
              }
          });
        }}
      >
        {({ errors, touched }) => (

          <div className="container">
            <div className="py-5 text-center">
              <img className="d-block mx-auto mb-4" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTdxP6jtxuzxOrHjumEk-8wI0yJrSeGAVVyppgr0g8MW5vXa0j1HzyP75y-wNwmPTGH8g&usqp=CAU" alt="" width={72} height={72} />
              <h2>Clients Stage 1 Form</h2> 
              <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p>
             </div>
            <div className="row">
              <div className="col-md-8 order-md-1">
                <h4 className="mb-3">Stage One</h4>
                <Form className="needs-validation" noValidate>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      {errors.name && touched.name ? (
                        <div>{errors.name} </div>
                      ) : null}
                      <label htmlFor="name">Name</label>
                      Enter your name:
                      <Field name="name" className="form-control" type="text" />
                      <small className="text-muted">Full name as on Documents</small>
                      <input type="text" className="form-control" id="name" />

                      <MyField property={'name'} label='Name' error={errors.name} touch={touched.name} />
                    </div>
                    <div className="col-md-6 mb-3">
                      {errors.name && touched.address ? (
                        <div>{errors.address} </div>
                      ) : null}
                      <label htmlFor="address">Address</label>
                      <Field name="address" className="form-control" type="text" placeholder="1234 Main St" />
                     <input type="text" className="form-control" id="address" />

                    </div>
                   </div>
                  <div className="mb-3">
                    {errors.photo && touched.photo ? (
                      <div>{errors.photo} </div>
                    ) : null}
                    <label htmlFor="photo">Photo</label>
                    <Field name="photo" className="form-control" type="file" />

                  </div>
                  <div className="mb-3">
                    {errors.name && touched.contact ? (
                      <div>{errors.contact} </div>
                    ) : null}
                    <label htmlFor="contact">Contact</label>
                    <Field name="contact" className="form-control" type="text" />
                  </div>
                  <div className="mb-3">
                    {errors.name && touched.dob ? (
                      <div>{errors.dob} </div>
                    ) : null}
                    <label htmlFor="dob">Date of Birth</label>
                    <Field name="dob" className="form-control" type="date" />
                  </div>
                  <div className="mb-3">
                    {errors.passport_no && touched.passport_no ? (
                      <div>{errors.passport_no} </div>
                    ) : null}
                    <label htmlFor="passport_no">Passport Number</label>
                    <Field name="passport_no" className="form-control" type="number" />

                  </div>
                  <div className="mb-3">
                    {errors.passport_exp_date && touched.dob ? (
                      <div>{errors.passport_exp_date} </div>
                    ) : null}
                    <label htmlFor="passport_exp_date">Passport expiry date</label>
                    <Field name="passport_exp_date" className="form-control" type="date" />
                  </div>
                  <div className="mb-3">
                    {errors.name && touched.cnic ? (
                      <div>{errors.cnic} </div>
                    ) : null}
                    <label htmlFor="cnic">CNIC Number</label>
                    <Field name="cnic" className="form-control" type="number" />
                  </div>
                  <div className="mb-3">
                    {errors.name && touched.qualification ? (
                      <div>{errors.qualification} </div>
                    ) : null}
                    <label htmlFor="qualification">Qualification (upload documents)</label>

                    <Field name="qualification" className="form-control" type="text" />
                  </div>
                   <div className="row">
                    <div className="col-md-3 mb-3">
                      {errors.name && touched.trade_applied ? (
                        <div>{errors.trade_applied} </div>
                      ) : null}
                      <label htmlFor="trade_applied">Trade Applied </label>
                      <Field name="trade_applied" className="form-control" type="text" />

                    </div>
                    <div className="col-md-3 mb-3">
                      {errors.name && touched.country_applied ? (
                        <div>{errors.country_applied} </div>
                      ) : null}
                      <label htmlFor="country_applied">Country</label>
                      <Field name="country_applied" className="form-control" type="text" />

                    </div>
                   <p>Who refered you?</p>
                    <div>
                      {errors.reference && touched.reference ? (
                        <div>{errors.reference} </div>
                      ) : null}

                      <Field name="reference" className="form-control" type="text" placeholder="Agency / Agent / Self" />

                    </div>
                 </div>
                 <br></br>
                 <div className="row">
                    <div className="col-md-4 mb-3">
                      {errors.name && touched.local_exp_yrs ? (
                        <div>{errors.local_exp_yrs} </div>
                      ) : null}
                      <label htmlFor="local_exp_yrs">Yaers of Local experiance</label>
                      <Field name="local_exp_yrs" className="form-control" type="number" />

                    </div>
                    <div className="col-md-4 mb-3">
                      {errors.name && touched.foreign_exp_country ? (
                        <div>{errors.foreign_exp_country} </div>
                      ) : null}
                      <label htmlFor="foreign_exp_country">Country of Foreign experiance</label>
                      <Field name="foreign_exp_country" className="form-control" type="text" />

                    </div>
                    <div className="col-md-4 mb-3">
                      {errors.name && touched.foreign_exp_yrs ? (
                        <div>{errors.foreign_exp_yrs} </div>
                      ) : null}
                      <label htmlFor="foreign_exp_yrs">Years of Foreign experiance</label>
                      <Field name="foreign_exp_yrs" className="form-control" type="number" />

                    </div>
                    <p>Enter the languages you can speak</p>
                    <div>
                      {errors.name && touched.languages ? (
                        <div>{errors.languages} </div>
                      ) : null}

                      <Field name="languages" className="form-control" type="text" placeholder="English,Urdu,Arabic,etc." />


                    </div>

                   </div>
                  {/* _______________________**********___________________________ */}
                  {/* <hr className="mb-4" />

                  <h4 className="mb-3">Stage Two</h4>

                  <div className="row">
                    <div className="mb-3">

                      <label htmlFor="med_photo">Medical Image</label>
                      <Field name="med_photo" className="form-control" type="file" />

                    </div>
                    <div className="mb-3">

                      <label htmlFor="contract_photo">Contract Image</label>
                      <Field name="contract_photo" className="form-control" type="file" />

                    </div>
                    <div className="mb-3">

                      <label htmlFor="contract_sub_date">Contract submission Date</label>
                      <Field name="contract_sub_date" className="form-control" type="date" />
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="payment_ttl">Total Payment</label>

                      <Field name="payment_ttl" className="form-control" type="number" />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="payment_pyd">Payment Payed</label>

                      <Field name="payment_pyd" className="form-control" type="number" />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="payment_lft">Payment Payable</label>

                      <Field name="payment_lft" className="form-control" type="number" />

                    </div>
                    </div> */}
                    {/* _______________________**********___________________________ */}
                    {/* <div>
                    <hr />
                    <h4 className="mb-3">Stage Three</h4>
                  </div> */}
                  {/* <div className="row">
                    <div className="mb-3">

                      <label htmlFor="calling_date">Calling Date</label>
                      <Field name="calling_date" className="form-control" type="date" />
                    </div>
                    <div className="mb-3">

                      <label htmlFor="protector_date">Protector Date</label>
                      <Field name="protector_date" className="form-control" type="date" />
                    </div>
                  </div>

                  <div className="mb-3">


                    <label htmlFor="visa_status">Visa status</label>
                    <Field name="visa_status" className="form-control" type="text" />

                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-2">
                      <label htmlFor="airline_ticket">Airline Ticket</label>
                      <Field name="airline_ticket" type="text" className="form-control" id="airline_ticket" />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="flying_date">Flying Date</label>
                      <Field name="flying_date" type="date" className="form-control" id="flying_date" />

                    </div>

                  </div> */}

                  <hr className="mb-4" />
                   <button className="btn btn-dark btn-lg btn-block" type="submit">Continue to checkout</button>

                 </Form>
              </div>
              
             </div>
            <footer className="my-5 pt-5 text-muted text-center text-small">
              <p className="mb-1">© 2017-2018 Company Name</p>
              <ul className="list-inline">
                <li className="list-inline-item"><a href="#">Privacy</a></li>
                <li className="list-inline-item"><a href="#">Terms</a></li>
                <li className="list-inline-item"><a href="#">Support</a></li>
              </ul>
            </footer>
          
        
        </div>
         )} 
         </Formik>

          </>
  );
}
;