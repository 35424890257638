import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup';

const EmployeeSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('*'),
  address: Yup.string()
    .min(10, 'Too Short!')
    .required('Required'),
  email: Yup.string()
    .min(5, 'Too Short!')
    .required('Required'),
  contact: Yup.string()
    .required('Required'),
 
});

export default function EmployeeForm() {
  return (
    <>
      
      <Formik
        validationSchema={EmployeeSchema}
        initialValues={{ name: "", address: "", photo: "", contact: "", email: "", }}
        onSubmit={async (values, { resetForm }) => {
         await fetch('http://localhost:3008/admin/api/resources/Employees/actions/new', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
          .then((response) => response.json())
          .then((json) => {
              console.log(json)
              if(json.notice.type ==='success'){
                  alert('Submitted Successfully')
                  resetForm();
              }
          });
        }}
      >
        {({ errors, touched }) => (
        

        <div className="container">
        <div className="py-5 text-center">
            <img className="d-block mx-auto mb-4" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTdxP6jtxuzxOrHjumEk-8wI0yJrSeGAVVyppgr0g8MW5vXa0j1HzyP75y-wNwmPTGH8g&usqp=CAU" alt="" width={72} height={72} />
            <h2>Employees Form</h2>
            {/* <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
        </div>
        <div className="row">
            <div className="col-md-8 order-md-1">
               
                <Form className="needs-validation" noValidate>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="name">Name</label>
                            {/* Enter your name: */}
                            <Field name="name" className="form-control" type="text" />
                            <small className="text-muted">Full name as on Documents</small>
                            {/* <input type="text" className="form-control" id="name" /> */}

                        </div>
                        <div className="col-md-6 mb-3">
                        {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="address">Address</label>
                            <Field name="address" className="form-control" type="text" placeholder="1234 Main St"/>
                            {/* <input type="text" className="form-control" id="address" /> */}
                           
                        </div>
                    </div>
                    <div className="mb-3">
                    {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                        <label htmlFor="photo">Photo</label>
                        <Field name="photo" className="form-control" type="file" />

                    </div>
                    <div className="mb-3">
                    {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="contact">Contact</label>
                            <Field name="contact" className="form-control" type="tel" />
                    </div>
                    <div className="mb-3">
                    {errors.name && touched.name ? (
                                <div>{errors.name} </div>
                            ) : null}
                            <label htmlFor="email">Email Address</label>
                            <Field name="email" className="form-control" type="email" placeholder="you@example.com"></Field>
                    </div>
                   

                       
                    
                   
                   
                    
                   
                    <button className="btn btn-dark btn-lg btn-block" type="submit">Continue to checkout</button>
                </Form>
            </div>
        </div>
        <footer className="my-5 pt-5 text-muted text-center text-small">
            <p className="mb-1">© 2017-2018 Company Name</p>
            <ul className="list-inline">
                <li className="list-inline-item"><a href="#">Privacy</a></li>
                <li className="list-inline-item"><a href="#">Terms</a></li>
                <li className="list-inline-item"><a href="#">Support</a></li>
            </ul>
        </footer>
    </div>


          

)}
</Formik>

</>
)
}