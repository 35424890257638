import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup';

const ClientS2Schema = Yup.object().shape({
    // med_photo: Yup.string()
    //     .required('*'),
    // contract_photo: Yup.string()
        // .required('Required'),
    contract_sub_date: Yup.date()
        .required('Required'),


});

export default function ClientS2Form() {
    return (
        <>

            <Formik
                validationSchema={ClientS2Schema}
                initialValues={{ med_photo: "", contract_photo: "", contract_sub_date: "", payment_ttl: "", payment_pyd: "", payment_lft: "", }}
                onSubmit={async (values, { resetForm }) => {
                    await fetch('http://localhost:3008/admin/api/resources/ClientsS2/actions/new', {
                        method: 'POST',
                        body: JSON.stringify(values),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                        },
                    })
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                        if(json.notice.type ==='success'){
                            alert('Submitted Successfully')
                            resetForm();
                        }
                    });
                }}
            >
                {({ errors, touched }) => (


                    <div className="container">
                        <div className="py-5 text-center">
                            <img className="d-block mx-auto mb-4" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTdxP6jtxuzxOrHjumEk-8wI0yJrSeGAVVyppgr0g8MW5vXa0j1HzyP75y-wNwmPTGH8g&usqp=CAU" alt="" width={72} height={72} />
                            <h2>Client Stage 2 Form</h2>
                            {/* <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
                        </div>
                        <h4 className="mb-3">Stage Two</h4>
                        <div className="row">
                            <div className="col-md-8 order-md-1">

                                <Form className="needs-validation" noValidate>

                                    <div className="row">
                                        <div className="mb-3">
                                        {errors.med_photo && touched.med_photo ? (
                        <div>{errors.med_photo} </div>
                      ) : null}

                                            <label htmlFor="med_photo">Medical Image</label>
                                            <Field name="med_photo" className="form-control" type="file" />



                                        </div>
                                        <div className="mb-3">

                                            <label htmlFor="contract_photo">Contract Image</label>
                                            <Field name="contract_photo" className="form-control" type="file" />

                                        </div>
                                    </div>
                                    <div className="mb-3">
                                    {errors.contract_sub_date && touched.contract_sub_date ? (
                        <div>{errors.contract_sub_date} </div>
                      ) : null}
                                        <label htmlFor="contract_sub_date">Contract submission Date</label>
                                        <Field name="contract_sub_date" className="form-control" type="date" />
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="payment_ttl">Total Payment</label>

                                            <Field name="payment_ttl" className="form-control" type="number" />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="payment_pyd">Payment Payed</label>

                                            <Field name="payment_pyd" className="form-control" type="number" />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="payment_lft">Payment Payable</label>

                                            <Field name="payment_lft" className="form-control" type="number" />

                                        </div>
                                    </div>







                                    <button className="btn btn-dark btn-lg btn-block" type="submit">Continue to checkout</button>
                                </Form>
                            </div>
                        </div>
                        <footer className="my-5 pt-5 text-muted text-center text-small">
                            <p className="mb-1">© 2017-2018 Company Name</p>
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="#">Privacy</a></li>
                                <li className="list-inline-item"><a href="#">Terms</a></li>
                                <li className="list-inline-item"><a href="#">Support</a></li>
                            </ul>
                        </footer>
                    </div>




                )}
            </Formik>

        </>
    )
}