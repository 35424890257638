// import logo from './logo.svg';
import './App.css';
// import ClientForm from './components/ClientForm';
// import AgentForm from './components/AgentForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import ClientS1Form from './components/ClientS1Form';
import ClientS2Form from './components/ClientS2Form';
import ClientS3Form from './components/ClientS3Form';
import EmployeeForm from './components/EmployeeForm';
import EmployersForm from './components/EmployersForm';
import EmployersForm2 from './components/EmployersForm2';
import Income from './components/income';
import Expense from './components/Expense';



function App() {
  return (
    <div className="App">
      <ClientS1Form/>
    </div>
  );
}

export default App;
