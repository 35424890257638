import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup';

const ExpenseSchema = Yup.object().shape({
    

});

export default function Expense() {
    return (
        <>

            <Formik
                validationSchema={ExpenseSchema}
                initialValues={{ office_exp_p: "", salaries_p: "", gov_fee_p: "", comission_p: "", others_p: "", office_exp_m: "", salaries_m: "", gov_fee_m: "", comission_m: "", others_m: "", }}
                onSubmit={async (values, { resetForm }) => {
                    await fetch('http://localhost:3008/admin/api/resources/Expense/actions/new', {
                        method: 'POST',
                        body: JSON.stringify(values),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                        },
                    })
                        .then((response) => response.json())
                        .then((json) => {
                            console.log(json)
                            if(json.notice.type ==='success'){
                                alert('Submitted Successfully')
                                resetForm();
                            }
                        });
                }}
            >
             


                    <div className="container">
                        <div className="py-5 text-center">
                            <img className="d-block mx-auto mb-4" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTdxP6jtxuzxOrHjumEk-8wI0yJrSeGAVVyppgr0g8MW5vXa0j1HzyP75y-wNwmPTGH8g&usqp=CAU" alt="" width={72} height={72} />
                            <h2>Expense Form</h2>
                            {/* <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
                        </div>
                        <h4>Pakistan Expenses</h4>
                        <div className="row">
                            <div className="col-md-8 order-md-1">

                                <Form className="needs-validation" noValidate>
                                    <div className="row">

                                        <div className="mb-3">

                                            <label htmlFor="office_exp_p">Office Expenses </label>
                                            <Field name="office_exp_p" className="form-control" type="number" />
                                        </div>
                                        <div className="mb-3">

                                            <label htmlFor="salaries_p">Salaries </label>
                                            <Field name="salaries_p" className="form-control" type="number" />

                                        </div>
                                        <div className="mb-3">


                                            <label htmlFor="gov_fee_p">Government fees</label>
                                            <Field name="gov_fee_p" className="form-control" type="number" />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <label htmlFor="comission_p">Comissions</label>
                                            <Field name="comission_p" type="number" className="form-control" id="comission_p" />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="others_p">Others</label>
                                            <Field name="others_p" type="number" className="form-control" id="others_p" />

                                        </div>
                                    </div>
                                    <br></br>
                                    <hr></hr>
                                    <h4>Malaysian Expenses</h4>
                                    <div className="row">

                                        <div className="mb-3">

                                            <label htmlFor="office_exp_m">Office Expenses </label>
                                            <Field name="office_exp_m" className="form-control" type="number" />
                                        </div>
                                        <div className="mb-3">

                                            <label htmlFor="salaries_m">Salaries </label>
                                            <Field name="salaries_m" className="form-control" type="number" />

                                        </div>
                                        <div className="mb-3">


                                            <label htmlFor="gov_fee_m">Government fees</label>
                                            <Field name="gov_fee_m" className="form-control" type="number" />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <label htmlFor="comission_m">Comissions</label>
                                            <Field name="comission_m" type="number" className="form-control" id="comission_m" />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="others_m">Others</label>
                                            <Field name="others_m" type="number" className="form-control" id="others_m" />

                                        </div>

                                    </div>





                                    <button className="btn btn-dark btn-lg btn-block" type="submit">Continue to checkout</button>
                                </Form>
                            </div>
                        </div>
                        <footer className="my-5 pt-5 text-muted text-center text-small">
                            <p className="mb-1">© 2017-2018 Company Name</p>
                            <ul className="list-inline">
                                <li className="list-inline-item"><a href="#">Privacy</a></li>
                                <li className="list-inline-item"><a href="#">Terms</a></li>
                                <li className="list-inline-item"><a href="#">Support</a></li>
                            </ul>
                        </footer>
                    </div>




                
            </Formik>

        </>
    )
}