import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup';

const EmployerForm2Schema = Yup.object().shape({
 

});

export default function EmployerForm2() {
  return (
    <>
      
      <Formik
        validationSchema={EmployerForm2Schema}
        initialValues={{ no_of_employees: "", trade: "", contract_date: "", demand_ltr: "", development_date: "", payment_ttl: "" , payment_pyd:"",payment_lft:"",}}
        onSubmit={async (values, { resetForm }) => {
          await fetch('http://localhost:3000/admin/api/resources/EmployersForm2/actions/new', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
          .then((response) => response.json())
          .then((json) => {
              console.log(json)
              if(json.notice.type ==='success'){
                  alert('Submitted Successfully')
                  resetForm();
              }
          });
        }}
      >
      
        

        <div className="container">
        <div className="py-5 text-center">
            <img className="d-block mx-auto mb-4" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTdxP6jtxuzxOrHjumEk-8wI0yJrSeGAVVyppgr0g8MW5vXa0j1HzyP75y-wNwmPTGH8g&usqp=CAU" alt="" width={72} height={72} />
            <h2>Employer Form 2</h2>
            {/* <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
        </div>
        
        <div className="row">
            <div className="col-md-8 order-md-1">
               
                <Form className="needs-validation" noValidate>
                   <div className="row">
                        <div className="col-md-6 mb-3">
                           
                            <label htmlFor="no_of_employees">Number Of Employees</label>
                       
                            <Field name="no_of_employees" className="form-control" type="number" />
                            
                           

                        </div>
                        <div className="col-md-6 mb-3">
                       
                            <label htmlFor="trade">Trade</label>
                            <Field name="trade" className="form-control" type="text" />
                            
                           
                        </div>
                    </div>
                  <div className='row' >
                  <div className="mb-3">
                    
                    <label htmlFor="contract_date">Date of Contract</label>
                    <Field name="contract_date" className="form-control" type="date" />
                  </div>
                  </div>
                  <div className="mb-3">
                  
                    <label htmlFor="demand_ltr">Demand Letter (upload documents / pdf)</label>

                    <Field name="demand_ltr" className="form-control" type="file" />
                  </div>
                  <div className="mb-3">
                   
                    <label htmlFor="development_date">Date of Development</label>
                    <Field name="development_date" className="form-control" type="date" />
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                    <label htmlFor="payment_ttl">Total Payment</label>
                       
                       <Field name="payment_ttl" className="form-control" type="number" />
                    </div>
                    <div className="col-md-3 mb-3">
                    <label htmlFor="payment_pyd">Payment Payed</label>
                       
                       <Field name="payment_pyd" className="form-control" type="number" />
                    </div>
                    <div className="col-md-3 mb-3">
                    <label htmlFor="payment_lft">Payment Payable</label>
                       
                       <Field name="payment_lft" className="form-control" type="number" />

                    </div>
                       
                   <hr></hr>
                   

                       
                   
                   
                   
                   </div>
                   
                    <button className="btn btn-dark btn-lg btn-block" type="submit">Continue to checkout</button>
                </Form>
            </div>
        </div>
        <footer className="my-5 pt-5 text-muted text-center text-small">
            <p className="mb-1">© 2017-2018 Company Name</p>
            <ul className="list-inline">
                <li className="list-inline-item"><a href="#">Privacy</a></li>
                <li className="list-inline-item"><a href="#">Terms</a></li>
                <li className="list-inline-item"><a href="#">Support</a></li>
            </ul>
        </footer>
    </div>


          


</Formik>

</>
)
}