import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup';

const IncomeSchema = Yup.object().shape({
    client_inc: Yup.string()
    .required('*'),

});

export default function Income() {
  return (
    <>

      <Formik
        validationSchema={IncomeSchema}
        initialValues={{ client_inc: "", agent_agency_inc: "", employer_inc: "", medical_exp: "", others: "", }}
        onSubmit={async (values, { resetForm }) => {
          await fetch('http://localhost:3008/admin/api/resources/Income/actions/new', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
          .then((response) => response.json())
          .then((json) => {
              console.log(json)
              if(json.notice.type ==='success'){
                  alert('Submitted Successfully')
                  resetForm();
              }
          });
            
        }}
      >
        {({ errors, touched }) => (


          <div className="container">
            <div className="py-5 text-center">
              <img className="d-block mx-auto mb-4" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTdxP6jtxuzxOrHjumEk-8wI0yJrSeGAVVyppgr0g8MW5vXa0j1HzyP75y-wNwmPTGH8g&usqp=CAU" alt="" width={72} height={72} />
              <h2>Income Form</h2>
              {/* <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
            </div>
            <div className="row">
              <div className="col-md-8 order-md-1">

                <Form className="needs-validation" noValidate>
                  <div className="row">

                    <div className="mb-3">
                     
                      <label htmlFor="client_inc">Client </label>
                      <Field name="client_inc" className="form-control" type="number" />
                    </div>
                    <div className="mb-3">

                      <label htmlFor="agent_agency_inc">Agent / Agency </label>
                      <Field name="agent_agency_inc" className="form-control" type="number" />

                    </div>
                    <div className="mb-3">


                      <label htmlFor="employer_inc">Employer</label>
                      <Field name="employer_inc" className="form-control" type="number" />

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-2">
                      <label htmlFor="medical_exp">Medical</label>
                      <Field name="medical_exp" type="number" className="form-control" id="medical_exp" />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="others">Others</label>
                      <Field name="others" type="number" className="form-control" id="others" />

                    </div>
                  </div>








                  <button className="btn btn-dark btn-lg btn-block" type="submit">Continue to checkout</button>
                </Form>
              </div>
            </div>
            <footer className="my-5 pt-5 text-muted text-center text-small">
              <p className="mb-1">© 2017-2018 Company Name</p>
              <ul className="list-inline">
                <li className="list-inline-item"><a href="#">Privacy</a></li>
                <li className="list-inline-item"><a href="#">Terms</a></li>
                <li className="list-inline-item"><a href="#">Support</a></li>
              </ul>
            </footer>
          </div>




        )}
      </Formik>

    </>
  )
}