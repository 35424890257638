import React from 'react'
import {Field} from 'formik'

export default function MyField({touch,error,property,label}) {
    return (
        <>
            <label htmlFor={property}>{label?? property}</label>
            <Field name={property} className="form-control"  />
            {error && touch ? (
                <div>{error} </div>
            ) : null}
        </>
    )
}
